import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import SiteMetadata from '../components/SiteMetadata'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ServicesStyles from '../styles/Services.module.sass'

/**
 * Display the services index template
 */
const ServicesTemplate = () => {
  const data = useStaticQuery(post)
  const { title } = SiteMetadata()

  return (
    <Layout
      className="is-page-services"
      seoTitle={`Storage Heater Services Dublin | ${title}`}
    >
      <div className="section">
        <Hero title="Our Services" showCTA="false" />

        <div
          className={`container services is-semi-narrow ${ServicesStyles.container}`}
        >
          <div className="columns is-8 is-multiline">
            {data.allMdx.edges.map(edge => {
              return (
                <Link
                  className="column is-half-tablet is-one-quarter-desktop"
                  to={`/${edge.node.frontmatter.redirectTo || edge.node.fields.slug}`}
                  key={edge.node.id}
                >
                  <article>
                    <header>
                      {edge.node.fields.image ? (
                        <Img
                          fluid={edge.node.fields.image.childImageSharp.fluid}
                        />
                      ) : null}
                    </header>
                    <h2>{edge.node.fields.shortestTitle}</h2>
                    <span className={`price ${ServicesStyles.price}`}>
                      &euro;{edge.node.frontmatter.price}
                    </span>
                    <span className={`button is-link ${ServicesStyles.button}`}>
                      View Service
                    </span>
                  </article>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default ServicesTemplate

export const post = graphql`
  {
    allMdx(
      limit: 1000
      filter: { frontmatter: { templateKey: { eq: "service" } } }
      sort: { fields: fields___shortestTitle, order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            shortestTitle
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          frontmatter {
            title
            price
            redirectTo
          }
        }
      }
    }
  }
`
